import React from 'react'
import Slick from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import style from './slider.module.css'
import slider1 from './slider.jpg'
import slider2 from './slider2.jpg'
import slider3 from './slider3.jpg'
import slider4 from './slider4.jpg'
import slider5 from './slider5.jpg'
import slider6 from './slider6.jpg'
import slider7 from './slider7.jpg'
import slider8 from './slider8.jpg'
import slider9 from './slider9.jpg'

const Slider = () => (
  <div className={style.slider}>
    <Slick
      infinite
      arrows={false}
      fade
      speed={500}
      autoplay
      autoplaySpeed={30000}
    >
      <img src={slider1} alt="" />
      <img src={slider2} alt="" />
      <img src={slider3} alt="" />
      <img src={slider4} alt="" />
      <img src={slider5} alt="" />
      <img src={slider6} alt="" />
      <img src={slider7} alt="" />
      <img src={slider8} alt="" />
      <img src={slider9} alt="" />
    </Slick>
  </div>
)

export default Slider
