import React from 'react'
import Link from 'gatsby-link'
import style from './footer.module.css'

const Footer = () => (
  <div className={style.footer}>
    <Link className={style.a} to="/datenschutz">
      Datenschutz
    </Link>
    <Link className={style.a} to="/impressum">
      Impressum
    </Link>
  </div>
)

export default Footer
