import React from 'react'
import Helmet from 'react-helmet'

import Header from '../components/Header'
import Footer from '../components/Footer'
import Slider from '../components/Slider'
import 'normalize.css'
import './index.css'
import style from './content.module.css'

const TemplateWrapper = ({ children }) => (
  <div>
    <Helmet
      title="Ferienwohnung Allgaier"
      meta={[
        { name: 'description', content: '' },
        {
          name: 'keywords',
          content: 'Ferienwohnung, Urlaub, Allgäu, Allgaier',
        },
      ]}
    />
    <Header />
    <Slider />
    <div className={style.content}>{children}</div>
    <Footer />
  </div>
)

export default TemplateWrapper
