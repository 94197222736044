import React from 'react'
import Link from 'gatsby-link'
import style from './header.module.css'

const Header = () => (
  <div className={style.header}>
    <Link to={'/'}>
      <h1 className={style.title}>Ferienwohnung Allgaier</h1>
    </Link>
    <nav>
      <Link to={'/zimmer'} className={style.menuitem}>
        Zimmer
      </Link>
      <Link to={'/anfahrt'} className={style.menuitem}>
        Anfahrt
      </Link>
      <Link to={'/kontakt'} className={style.menuitem}>
        Kontakt
      </Link>
    </nav>
  </div>
)

export default Header
